/* You can add global styles to this file, and also import other style files */
/* roboto-condensed-200 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 200;
  src: url("../public/fonts/roboto-condensed/roboto-condensed-v27-latin_latin-ext-200.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-300 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  src: url("../public/fonts/roboto-condensed/roboto-condensed-v27-latin_latin-ext-300.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/roboto-condensed/roboto-condensed-v27-latin_latin-ext-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-500 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/roboto-condensed/roboto-condensed-v27-latin_latin-ext-500.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-600 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 600;
  src: url("../public/fonts/roboto-condensed/roboto-condensed-v27-latin_latin-ext-600.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("../public/fonts/roboto-condensed/roboto-condensed-v27-latin_latin-ext-700.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-800 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 800;
  src: url("../public/fonts/roboto-condensed/roboto-condensed-v27-latin_latin-ext-800.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-900 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 900;
  src: url("../public/fonts/roboto-condensed/roboto-condensed-v27-latin_latin-ext-900.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@angular/cdk/overlay-prebuilt.css";

@layer base {
  :root {
    --color-white: rgb(255, 255, 255);
    --color-cinder: rgb(15, 22, 26);
    --color-dark-jungle-green: rgb(29, 29, 29);
    --color-gainsboro: rgb(222, 222, 222);
    --color-ferrari-red: rgb(235, 10, 10);
    --color-dawn-pink: rgb(237, 237, 226);
    --color-granite: rgb(128, 128, 128);
    --color-davy-grey: rgb(87, 87, 87);
    --color-star-dust: rgb(165, 157, 157);
    --color-cloudy-grey: rgb(105, 105, 105);
    --color-soft-peach: rgb(238, 238, 238);
    --color-cinder: rgb(15, 22, 26);
    --color-lava-red: rgb(237, 28, 36);
    --color-dark-slate-grey: rgb(244, 244, 244);
    body.mode-dark {
      --color-dark-slate-grey: rgb(34, 49, 57);
      --color-cloudy-grey: rgb(255, 255, 255);
      --color-lava-red: rgb(255, 255, 255);
      --color-gainsboro: rgb(255, 255, 255);
      --color-white: rgb(15, 22, 26);
      --color-soft-peach: rgb(34, 49, 57);
      --color-cinder: rgb(255, 255, 255);
      --color-dark-jungle-green: rgb(255, 255, 255);
      --color-gainsboro: rgb(222, 222, 222);
      --color-ferrari-red: rgb(235, 10, 10);
      --color-dawn-pink: rgb(34, 49, 57);
      // --color-granite: rgb(255, 255, 255);
      --color-davy-grey: rgb(255, 255, 255);

      --color-blue: 0, 190, 255;
      --color-green: 0, 255, 125;
      --color-gray-dark: 21, 33, 39;
      --color-dark: 15, 22, 26;
      --color-red: 153, 17, 20;
      --color-gray: 103, 103, 110;
      // --color-white: 255 255 255;
      --color-black: 0 0 0;
      --color-blue-bg: 21 33 39;
      --color-question-gray: 217 217 217;
      --color-pagination: 255 255 255;
      --color-RISD-blue: 0 95 255;
      --color-dodger-blue: 0 145 255;
      --color-card: 34 49 57;
      --color-dark-inverse: 255, 255, 255;
      --color-slider: 8 106 140;
    }
  }
}

body {
  font-family: "Roboto Condensed";
  background-color: var(--color-white);
}
.sp-content {
  & > * {
    margin: 28px 0 20px 0;
  }
  p {
    background-color: var(--color-white) !important;
    color: var(--color-dark-jungle-green) !important;
    margin-bottom: 15px;
    font-size: 20px;
  }
  h2 {
    line-height: 35px;
    font-size: 32px;
    font-weight: bold;
  }
  h3 {
    line-height: 33px;
    font-size: 28px;
    font-weight: bold;
    line-height: 32px;
  }
  h4 {
    line-height: 33px;
    font-size: 26px;
    font-weight: bold;
  }
  h5 {
    line-height: 33px;
    font-size: 24px;
    font-weight: bold;
  }
  h6 {
    line-height: 33px;
    font-size: 18px;
    font-weight: bold;
  }
  & > img {
    max-width: 100%;
    height: auto;
    width: 100%;
  }

  figure.image {
    width: 100% !important;
    position: relative;
    cursor: pointer;
    img {
      margin-bottom: 30px !important;
      margin-inline: auto;
    }
    figcaption:not(.img-source) {
      position: absolute;
      bottom: 0;
      transform: translate(-50%, 100%);
      left: 50%;
      font-size: 12px;
      font-style: italic;
      width: 100%;
      color: var(--color-granite);
      text-align: center;
    }

    figcaption.img-source {
      position: absolute;
      background-color: var(--color-ferrari-red);
      bottom: 0;
      left: 0;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #fff;
      font-weight: bold;
      font-style: italic;
    }
  }

  figure.table {
    width: 100% !important;
    display: block;
    overflow-x: scroll;
    table {
      width: 100%;
      min-width: 600px;
      color: rgb(var(--color-white)) !important;
      background-color: rgb(var(--color-dark)) !important;
      tr {
        border-bottom: 1px solid rgb(var(--color-white));
        td {
          color: rgb(var(--color-white)) !important;
          background-color: rgb(var(--color-dark)) !important;
        }
      }
    }
  }

  p {
    a {
      color: var(--color-ferrari-red);
      font-weight: 700;
      text-decoration: underline;
      word-break: break-word;
    }
  }
  ul {
    li {
      list-style-type: disc;
      margin-left: 28px;
      margin-bottom: 12;
    }
  }
  ol {
    li {
      list-style-type: decimal;
      margin-left: 32px;
      margin-bottom: 12px;
    }
  }
  & > blockquote {
    font-size: 1.4em;
    margin: 50px auto;
    font-family: Open Sans;
    font-style: italic;
    color: rgb(var(--color-white));
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid rgb(var(--color-blue));
    line-height: 1.6;
    position: relative;
    background-color: rgb(var(--color-dark));

    &::before {
      font-family: Arial;
      content: "\201C";
      color: rgb(var(--color-blue));
      font-size: 4em;
      position: absolute;
      left: 10px;
      top: -20px;
    }
  }

  .embeded-gallery-block {
    * {
      margin-bottom: 0 !important;
    }
  }

  .article-box-wrapper {
    background-color: var(--color-soft-peach);
    color: rgb(var(--color-white));
    * {
      margin-bottom: 0;
    }
    .row {
      display: flex;
    }
    .col-4 {
      width: 30%;
      img {
        aspect-ratio: 16 / 9;
        width: 100%;
        height: auto;
      }
    }
    .col-8 {
      width: 68%;
      display: flex;
      align-items: center;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .card .row .card-title {
      font-weight: 500;
    }
  }

  .intro-box-wrapper {
    padding: 1rem;
    background-color: rgb(var(--color-blue-bg));
    color: rgb(var(--color-white));
    ul,
    ol {
      li {
        margin-bottom: 0;
      }
    }
  }
  p:has(iframe[src^='https://www.facebook.com/plugins/video'])
  {
    aspect-ratio: 560 / 429;
    iframe[src^='https://www.facebook.com/plugins/video']
    {
      margin: 0 auto !important;
      overflow: hidden;
      width: 100%;
      height: max(412px, 100%);
      display: block;
    }
  }

  p {
    iframe {
      margin: 0 auto;
      max-width: 100%;
    }
  }
  .raw-html-embed {
    overflow: hidden;
    margin: 0 auto 20px !important;
    div.twitter-tweet {
      margin: 0 auto;
    }

    iframe {
      margin: 0 auto !important;
      overflow: hidden;
      max-width: 100%;
      background-color: white !important;
    }
    .instagram-media {
      margin: 0 auto !important;
    }
    iframe[title="YouTube video player"] {
      margin: 30px 0;
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }
    .brid {
      width: 100% !important;
      height: calc(100% + 20px) !important;
      .brid-controls {
        height: 5.3em;
        // .brid-volume-control {
        //   margin-left: -4em !important;
        //   margin-top: 1.3em !important;
        // }
      }
    }
  }

  .raw-html-embed.carousel {
    iframe {
      margin: 0 auto !important;
      overflow: hidden;
      max-width: 100%;
      background-color: white !important;
      position: fixed !important;
    }
  }

  .blog-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid rgb(var(--color-blue));
    .blog-box-date {
      align-self: baseline;
      flex-shrink: 1;
      margin-right: 16px;
      font-size: 24px;
      font-weight: 600;
      // flex-basis: 15%;
      color: rgb(var(--color-blue));
      white-space: nowrap;
    }
    .blog-box-title {
      font-size: 24px;
      font-weight: 600;
      flex-basis: 80%;
      padding-left: 8px;
      flex-grow: 1;
    }
    .blog-box-description {
      margin-top: 15px;
      flex-basis: 100%;
      flex-grow: 1;
      font-size: 18px;
      font-weight: 400;
      & > :last-child {
        margin-bottom: 15px;
      }
    }
    &:last-of-type {
      border: none;
    }
  }
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .img-inner {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      height: auto;
      max-height: 80vh;
    }
  }
  .close-ligtbox {
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    cursor: pointer;
  }
}

.brid {
  width: 100% !important;
  height: calc(100% + 20px) !important;
  .brid-controls {
    height: 5.3em;
    // .brid-volume-control {
    //   margin-left: -4em !important;
    //   margin-top: 1.3em !important;
    // }
  }
}

.menu-item:hover .submenu {
  display: block;
}
.swiper-button-disabled {
  opacity: 0.3;
}

.pagination {
  .ngx-pagination .pagination-previous a:before,
  .ngx-pagination .pagination-previous.disabled:before {
    content: "\3C";
    margin-right: 2px;
  }
  .ngx-pagination .pagination-next a:after,
  .ngx-pagination .pagination-next.disabled:after {
    content: "\3E";
    margin-left: 2px;
  }
  .ngx-pagination .pagination-previous.disabled,
  .ngx-pagination .pagination-next.disabled {
    opacity: 0;
  }

  .current {
    background-color: var(--color-ferrari-red) !important;
    color: var(--color-white);
    font-size: 18px;
  }
  .ngx-pagination {
    li {
      &.small-screen {
        color: var(--color-white);
        background-color: var(--color-ferrari-red);
        padding: 0.1875rem 0.625rem;
      }
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    a {
      color: #7e8299;
      font-weight: bold;
      font-size: 18px;
      background-color: var(--color-dark-slate-grey);
    }
  }
}

.sidscrapers {
  .left,
  .right {
    position: fixed;
    top: 104px;
  }
}

.ad-container {
  & > div {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
}

.tv-program-description {
  iframe {
    margin-top: 8px;
  }
}

// .mode-dark {
//   .custom-header-img {
//     background-image: url("./assets/images/21dan.webp");
//   }
// }

// .custom-header-img {
//     background-image: url("./assets/images/21dan.webp");
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: center left;
//     color: transparent !important;
//     a.active {
//         color: transparent !important;
//     }
//     &:hover {
//         a {
//             color: transparent !important;
//         }
//     }
// }

.card-shadow {
  box-shadow: 0 4px 10px rgba($color: #000000, $alpha: 0.1);
}

.grecaptcha-badge {
  opacity: 0;
}

@media screen and (max-width: 640px) {
  .sp-content {
    .article-box-wrapper {
      h5 {
        line-height: 1.2;
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    & > blockquote {
      font-size: 1em;
      margin: 20px auto;
      font-family: Open Sans;
      font-style: italic;
      color: rgb(var(--color-white));
      padding: 1.2em 30px 1.2em 30px;
      border-left: 4px solid rgb(var(--color-blue));
      line-height: 1.6;
      position: relative;
      background-color: rgb(var(--color-dark));

      &::before {
        font-family: Arial;
        content: "\201C";
        color: rgb(var(--color-blue));
        font-size: 1.5em;
        position: absolute;
        left: 10px;
        top: 18px;
      }
    }
    .blog-box-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-bottom: 2px solid rgb(var(--color-blue));
      .blog-box-date {
        align-self: baseline;
        flex-shrink: 1;
        // margin-right: 8px;
        font-size: 24px;
        font-weight: 600;
        flex-basis: 100%;
        color: rgb(var(--color-blue));
        white-space: nowrap;
        margin-bottom: 15px;
      }
      .blog-box-title {
        font-size: 24px;
        font-weight: 600;
        flex-basis: 100%;
        padding-left: 0px;
        // flex-grow: 1;
      }
      .blog-box-description {
        margin-top: 15px;
        flex-basis: 100%;
        flex-grow: 1;
        font-size: 18px;
        font-weight: 400;
      }
      &:last-of-type {
        border: none;
      }
    }
  }
}
